import React, {useEffect, useState} from "react";
import Layout from "../components/layouts/layout";

export default function Contacto() {
    const [splitUrl, setSplitUrl] = useState([]);
    
    useEffect(() => {

    }, []);

    return (
        <>
                <Layout>
            <iframe
                src={"https://www.riverorenta.mx/produccion/nissan/valua-tu-auto"}
                style={{
                    width: '100%',
                    height: 1100,
                    border: "none",
                }}
            />
        </Layout>
        </>

    )
}